<template>
  <div class="py-4 container-fluid ">
    <div class="mx-4 mt-5 overflow-hidden card card-body shadow-blur text-primary bg-white ">
      <div class="row  gx-4 flex-wrap justify-content-start">
        <div class="col-auto my-auto">
          <div class="h-100">
            <h5 class="mb-0">Social Media Content-Plan Workbook</h5>
          </div>
        </div>
      </div>
    </div>
    <div class="py-4 container-fluid">
      <div class="mb-4 card ps-4" v-if="formData.send">
        <div class="d-flex py-3 align-items-center font-weight-bold">
          Unternehmensdetails
        </div>
        <table>
          <tr>
            <td>Name</td>
            <td>{{ formData.name }}</td>
          </tr>
          <tr>
            <td>Beschreibung</td>
            <td>{{ formData.description }}</td>
          </tr>
          <tr>
            <td>Anazhl der Mitarbeiter</td>
            <td>{{ formData.employees }}</td>
          </tr>
          <tr>
            <td>Kernbotschaft</td>
            <td>{{ formData.coreMessage }}</td>
          </tr>
          <tr>
            <td>Unternehmenswerte</td>
            <td>{{ formData.companyValues }}</td>
          </tr>
        </table>
        <div class="d-flex py-3 align-items-center font-weight-bold">
          Unternehmensziele:
        </div>
        <div class="d-flex row ps-2">
          <span class="d-flex w-auto pe-1 ps-0" v-if="formData.moreFollower">Bekanntheit steigern,</span>
          <span class="d-flex w-auto pe-1 ps-0" v-if="formData.employerBranding">Mitarbeitergewinnung (Employer Branding),</span>
          <span class="d-flex w-auto pe-1 ps-0" v-if="formData.customerBond">Kundenbindung stärken,</span>
          <span class="d-flex w-auto pe-1 ps-0" v-if="formData.newCustomers">Neukundengewinnung,</span>
          <span class="d-flex w-auto pe-1 ps-0" v-if="formData.newAudience">Neue Zielgruppen erschließen,</span>
          <span class="d-flex w-auto pe-1 ps-0" v-if="formData.expertKnowlege">Expertenwissen vermitteln,</span>
          <span class="d-flex w-auto pe-1 ps-0" v-if="formData.communityBuild">Community-Aufbau,</span>
          <span class="d-flex w-auto pe-1 ps-0" v-if="formData.engagement">Online-Präsenz stärken,</span>
          <span class="d-flex w-auto pe-1 ps-0" v-if="formData.customerBond">Kundenbindung stärken,</span>
          <span class="d-flex w-auto pe-1 ps-0"
                v-if="formData.furtherGoals">Weitere Ziele: ({{ formData.furtherGoals }})</span>
        </div>
        <div class="d-flex py-3 align-items-center font-weight-bold">
          Zielgruppe:
        </div>
        <div class="d-flex row ps-2">
          <span class="p-0">Alter:</span>
          <span class="d-flex w-auto pe-1 ps-0" v-if="formData.eighteen">18-24 Jahre,</span>
          <span class="d-flex w-auto pe-1 ps-0" v-if="formData.twentyFive">25-34 Jahre,</span>
          <span class="d-flex w-auto pe-1 ps-0" v-if="formData.thirtyFive">35-44 Jahre,</span>
          <span class="d-flex w-auto pe-1 ps-0" v-if="formData.fortyFive">45-54 Jahre,</span>
          <span class="d-flex w-auto pe-1 ps-0" v-if="formData.fiftyFive">55+ Jahre</span>
        </div>
        <br>
        <div class="d-flex row ps-2">
          <span class="p-0">Entfernung zum Unternehmensstandort:</span>
          <span class="d-flex w-auto pe-1 ps-0" v-if="formData.sameCity">Im gleichen Stadtteil/Gebiet wie das Unternehmen,</span>
          <span class="d-flex w-auto pe-1 ps-0" v-if="formData.regional">Regional (Bundesland/Landkreis),</span>
          <span class="d-flex w-auto pe-1 ps-0" v-if="formData.germany">Deutschlandweit,</span>
          <span class="d-flex w-auto pe-1 ps-0" v-if="formData.everywhere">Keine spezifische Begrenzung</span>
        </div>
        <br>
        <div class="d-flex row ps-2">
          <span class="p-0">Berufliche Situation:</span>
          <span class="d-flex w-auto pe-1 ps-0" v-if="formData.student">Schüler/Student,</span>
          <span class="d-flex w-auto pe-1 ps-0" v-if="formData.working">Berufstätig,</span>
          <span class="d-flex w-auto pe-1 ps-0" v-if="formData.independent">Selbstständig,</span>
          <span class="d-flex w-auto pe-1 ps-0" v-if="formData.ceo">Geschäftsführung,</span>
          <span class="d-flex w-auto pe-1 ps-0" v-if="formData.everyone">Keine spezifische Zielgruppe</span>
        </div>
        <br>
        <div class="d-flex row ps-2">
          <span class="p-0">Interessen der Zielgruppe:</span>
          <span class="d-flex w-auto pe-1 ps-0" v-if="formData.sustainability">Nachhaltigkeit,</span>
          <span class="d-flex w-auto pe-1 ps-0" v-if="formData.technology">Inovation/Technologie,</span>
          <span class="d-flex w-auto pe-1 ps-0" v-if="formData.education">Bildung,</span>
          <span class="d-flex w-auto pe-1 ps-0" v-if="formData.entertainment">Unterhaltung,</span>
          <span class="d-flex w-auto pe-1 ps-0"
                v-if="formData.otherInterests">Weitere Ziele: ({{ formData.otherInterests }}),</span>
          <span class="d-flex w-auto pe-1 ps-0" v-if="formData.noInterests">Keine Angaben</span>
        </div>
        <div class="d-flex py-3 align-items-center font-weight-bold">
          Social Media:
        </div>
        <div class=" row ps-2">
          <div class=" w-auto pe-1 ps-0" v-if="formData.usesSocialMedia">
            <div class=" flex-column pe-1 ps-0" v-if="formData.usesInstagram">
              Instagram Account: {{ formData.instagramAccountName }}<br>
            </div>
            <div class=" flex-column pe-1 ps-0" v-if="formData.usesFacebook">
              Facebook Account: {{ formData.facebookAccountName }}<br>
            </div>
            <div class=" flex-column pe-1 ps-0" v-if="formData.usesLinkedIn">
              LinkedIn Account: {{ formData.linkedInAccountName }}<br>
            </div>
            <div class=" flex-column pe-1 ps-0" v-if="formData.usesTikTok">
              TikTok Account: {{ formData.tikTokAccountName }}<br>
            </div>
            <div class=""><br>
              <span class="p-0">Vergangene Erfahrungen:</span>
              <div class=" flex-column pe-1 ps-0" v-if="formData.previousExp">
                {{ formData.previousExp }}
              </div>
            </div>
            <br>
            <div>
              <span class="p-0">Bisherige Herausforderungen:</span>
              <div class=" flex-column pe-1 ps-0" v-if="formData.challenges">
                {{ formData.challenges }}
              </div>
            </div>
          </div>
          <span class="d-flex w-auto pe-1 ps-0" v-if="!formData.usesSocialMedia">Kunde benutzt keine Sozial Media Plattformen.</span>
        </div>
        <br>
        <div class="d-flex row ps-2">
          <span class="p-0">Vorstellung der Inhalte auf dem Social Media Account:</span>
          <span class="d-flex w-auto pe-1 ps-0" v-if="formData.companyProfile">Unternehmensdarstellung,</span>
          <span class="d-flex w-auto pe-1 ps-0"
                v-if="formData.teamIntro">Vorstellung des Teams/ Mitarbeiterporträts,</span>
          <span class="d-flex w-auto pe-1 ps-0" v-if="formData.successStories">Erfolgsgeschichten und Projekte,</span>
          <span class="d-flex w-auto pe-1 ps-0" v-if="formData.customerEngagement">Kundeninteraktion/ Antworten auf häufige Kundenfragen (FAQs),</span>
          <span class="d-flex w-auto pe-1 ps-0" v-if="formData.contestsChallenges">Gewinnspiele oder Challanges,</span>
          <span class="d-flex w-auto pe-1 ps-0"
                v-if="formData.industryTips">Fachartikel oder Tipps aus der Branche,</span>
          <span class="d-flex w-auto pe-1 ps-0" v-if="formData.howToVideos">How-To-Videos oder Tutorials,</span>
          <span class="d-flex w-auto pe-1 ps-0"
                v-if="formData.contentCompanyValues">Präsentation der Unternehmenswerte,</span>
          <span class="d-flex w-auto pe-1 ps-0" v-if="formData.behindScenes">Einblicke in den Arbeitsalltag (Behind-the-Scenes),</span>
          <span class="d-flex w-auto pe-1 ps-0" v-if="formData.customerReviews">Veröffentlichung von Kundenbewertungen und Testimonials,</span>
          <span class="d-flex w-auto pe-1 ps-0"
                v-if="formData.miscellaneous"> Weitere Vorstellungen:({{ formData.miscellaneous }})</span>
        </div>
        <br>
        <div class="p-0">Häufigkeit der Posts in der Woche:
          <div class=" flex-column pe-1 ps-0" v-if="formData.postFrequencyInstagram">
            Auf Instagram: {{ formData.postFrequencyInstagram }}
          </div>
          <div class=" flex-column pe-1 ps-0" v-if="formData.postFrequencyFacebook">
            Auf Facebook: {{ formData.postFrequencyFacebook }}
          </div>
          <div class=" flex-column pe-1 ps-0" v-if="formData.postFrequencyLinkedIn">
            Auf LinkedIn: {{ formData.postFrequencyLinkedIn }}
          </div>
          <div class=" flex-column pe-1 ps-0" v-if="formData.postFrequencyTikTok">
            Auf Tiktok: {{ formData.postFrequencyTikTok }}
          </div>
        </div>
        <br>
        <div class="d-flex py-3 align-items-center font-weight-bold">
          Social Media Content
        </div>
        <div>
          <span class="p-0 font-weight-bold">Bilderstellung:</span><br>
          <span class="p-0">Worauf sollen ihre Beiträge basieren?:</span><br>
          <span class="w-auto pe-1 ps-0" v-if="formData.existingContent">Bereits vorhandenes Material,</span>
          <span class="w-auto pe-1 ps-0" v-if="formData.theirContent">Wird vom Kunden erstellt und für uns bereitgestellt,</span>
          <span class="w-auto pe-1 ps-0" v-if="formData.ourContent">Soll von uns für den Kunden erstellt werden,</span>
        </div>
        <br>
        <div>
          <span class="p-0 font-weight-bold">Bildunterschriften und Beschreibungen:</span><br>
          <span class="p-0" v-if="formData.generateText">Bildunterschriften und Hashtags sollen automatisch generiert werden</span>
          <span class="p-0" v-if="!formData.generateText">Bildunterschriften und Hashtags sollen nicht automatisch generiert werden</span>
        </div><br>
        <div class="d-flex py-3 align-items-center font-weight-bold">
          Branding und visuelle Darstellung
        </div>
        <div>
          <span class="p-0" v-if="formData.brandColor">Markenfarben: {{formData.brandColor}}<br></span>
          <span class="p-0" v-if="!formData.brandColor">Markenfarben: Nicht vorhanden<br></span>
          <span class="p-0 mb-2" v-if="formData.logoInPosts">Logo: Vorhanden<br></span>
          <span class="p-0" v-if="!formData.logoInPosts">Logo: Nicht vorhanden<br></span>
        </div><br>
        <div>
          <span class="p-0 font-weight-bold">Kommunikationsstil und Ton:</span><br>
          <span class="w-auto pe-1 ps-0" v-if="formData.easyAndFun">Locker und humorvoll,</span>
          <span class="w-auto pe-1 ps-0" v-if="formData.profAndInfo">Professionell und informativ,</span>
          <span class="w-auto pe-1 ps-0" v-if="formData.emotional">Emotional und verbindend,</span>
          <span class="w-auto pe-1 ps-0" v-if="formData.motivation">Inspirierend und motivierend,</span>
          <span class="w-auto pe-1 ps-0" v-if="formData.noStyle">Keine Präferenz,</span>
          <span class="w-auto pe-1 ps-0" v-if="formData.otherStyle">Anderer Stil: ({{formData.otherStyle}})</span>
        </div><br>
        <div class="d-flex py-3 align-items-center font-weight-bold">
          Werbebudget und bezahlte Werbung:
        </div>
        <div v-if="formData.adsBudget">
          <span class="p-0 font-weight-bold">Werbebudget: {{formData.adsBudget}}</span><br><br>
          <span class="p-0 font-weight-bold">Welche Ziele sollen damit verfolgt werden:</span><br>
          <span class="w-auto pe-1 ps-0" v-if="formData.adReach">Mehr Reichweite,</span>
          <span class="w-auto pe-1 ps-0" v-if="formData.adFollower">Mehr Follower,</span>
          <span class="w-auto pe-1 ps-0" v-if="formData.adVisitor">Mehr Website-Besuche,</span>
          <span class="w-auto pe-1 ps-0" v-if="formData.adSales">Produktverkäufe steigern,</span>
          <span class="w-auto pe-1 ps-0" v-if="formData.adElse">Anderer Ziele: ({{formData.adElse}})</span>
        </div>
        <div v-else>
          Kunde plant keine zusätzlichen Werbeanzeigen
        </div>
        <br>
        <div>
          <span class="p-0 font-weight-bold">Zeitplan und Meilensteine:<br></span>
          <span class="w-auto pe-1 ps-0">Startzeitraum: {{formData.startDate}}<br></span>
          <span class="w-auto pe-1 ps-0">Meilensteine: {{formData.milestones}}</span>
        </div><br>
        <div>
          <span class="p-0 font-weight-bold">Ansprechpartner:<br> </span>
          <span class="p-0 font-weight-bold">Person 1:<br></span>
          <span class="w-auto pe-1 ps-0">Name: {{formData.firstPersonName}}<br></span>
          <span class="w-auto pe-1 ps-0">E-Mail: {{formData.firstPersonEmail}}<br></span>
          <span class="w-auto pe-1 ps-0">Telefonnummer: {{formData.firstPersonNumber}}<br></span>
          <span class="w-auto pe-1 ps-0">Position: {{formData.firstPersonPos}}<br><br></span>

          <span class="p-0 font-weight-bold">Person 2:<br></span>
          <span class="w-auto pe-1 ps-0">Name: {{formData.secondPersonName}}<br></span>
          <span class="w-auto pe-1 ps-0">E-Mail: {{formData.secondPersonEmail}}<br></span>
          <span class="w-auto pe-1 ps-0">Telefonnummer: {{formData.secondPersonNumber}}<br></span>
          <span class="w-auto pe-1 ps-0">Position: {{formData.secondPersonPos}}</span>
        </div><br>
        <div class="mb-2">
          <span class="p-0 font-weight-bold">Weitere Informationen: <br></span>
          <span class="w-auto pe-1 ps-0">{{formData.extraWish}}</span>
        </div>
      </div>
      <div class="mb-4 card p-4" v-else>Der Kunde hat noch nicht auf Senden geklickt</div>
    </div>
  </div>
</template>


<script>

import {apiGet} from "@/api/api";

export default {

  data: function () {
    return {
      formData: [],
      loadingState: false,
      companyId: 0,
    }
  },

  computed: {
    activeId() {
      return parseInt(this.$store.state.activeId);
    },
  },

  methods: {
    async setForm() {
      this.loadingState = true;

      await apiGet(localStorage.getItem('adminId'), '/form/id/' + this.companyId).then((resp) => {
        this.formData = {
          name: resp.data.name,
          description: resp.data.description,
          employees: resp.data.employees,
          coreMessage: resp.data.coreMessage,
          companyValues: resp.data.companyValues,
          moreFollower: resp.data.moreFollower,
          employerBranding: resp.data.employerBranding,
          customerBond: resp.data.customerBond,
          newCustomers: resp.data.newCustomers,
          newAudience: resp.data.newAudience,
          expertKnowlege: resp.data.expertKnowlege,
          communityBuild: resp.data.communityBuild,
          engagement: resp.data.engagement,
          furtherGoals: resp.data.furtherGoals,
          eighteen: resp.data.eighteen,
          twentyFive: resp.data.twentyFive,
          thirtyFive: resp.data.thirtyFive,
          fortyFive: resp.data.fortyFive,
          fiftyFive: resp.data.fiftyFive,
          sameCity: resp.data.sameCity,
          regional: resp.data.regional,
          germany: resp.data.germany,
          everywhere: resp.data.everywhere,
          student: resp.data.student,
          working: resp.data.working,
          independent: resp.data.independent,
          ceo: resp.data.ceo,
          everyone: resp.data.everyone,
          sustainability: resp.data.sustainability,
          technology: resp.data.technology,
          education: resp.data.education,
          entertainment: resp.data.entertainment,
          otherInterests: resp.data.otherInterests,
          noInterests: resp.data.noInterests,
          usesSocialMedia: resp.data.usesSocialMedia,
          usesInstagram: resp.data.usesInstagram,
          instagramAccountName: resp.data.instagramAccountName,
          usesFacebook: resp.data.usesFacebook,
          facebookAccountName: resp.data.facebookAccountName,
          usesLinkedIn: resp.data.usesLinkedIn,
          linkedInAccountName: resp.data.linkedInAccountName,
          usesTikTok: resp.data.usesTikTok,
          tikTokAccountName: resp.data.tikTokAccountName,
          previousExp: resp.data.previousExp,
          challenges: resp.data.challenges,
          companyProfile: resp.data.companyProfile,
          teamIntro: resp.data.teamIntro,
          successStories: resp.data.successStories,
          customerEngagement: resp.data.customerEngagement,
          contestsChallenges: resp.data.contestsChallenges,
          industryTips: resp.data.industryTips,
          howToVideos: resp.data.howToVideos,
          contentCompanyValues: resp.data.contentCompanyValues,
          behindScenes: resp.data.behindScenes,
          customerReviews: resp.data.customerReviews,
          miscellaneous: resp.data.miscellaneous,
          postFrequencyInstagram: resp.data.postFrequencyInstagram,
          postFrequencyFacebook: resp.data.postFrequencyFacebook,
          postFrequencyLinkedIn: resp.data.postFrequencyLinkedIn,
          postFrequencyTikTok: resp.data.postFrequencyTikTok,
          existingContent: resp.data.existingContent,
          theirContent: resp.data.theirContent,
          ourContent: resp.data.ourContent,
          generateText: resp.data.generateText,
          brandColor: resp.data.brandColor,
          logoInPosts: resp.data.logoInPosts,
          easyAndFun: resp.data.easyAndFun,
          profAndInfo: resp.data.profAndInfo,
          emotional: resp.data.emotional,
          motivation: resp.data.motivation,
          noStyle: resp.data.noStyle,
          otherStyle: resp.data.otherStyle,
          adsBudget: resp.data.adsBudget,
          adReach: resp.data.adReach,
          adFollower: resp.data.adFollower,
          adVisitor: resp.data.adVisitor,
          adSales: resp.data.adSales,
          adElse: resp.data.adElse,
          startDate: resp.data.startDate,
          milestones: resp.data.milestones,
          firstPersonName: resp.data.firstPersonName,
          firstPersonEmail: resp.data.firstPersonEmail,
          firstPersonNumber: resp.data.firstPersonNumber,
          firstPersonPos: resp.data.firstPersonPos,
          secondPersonName: resp.data.secondPersonName,
          secondPersonEmail: resp.data.secondPersonEmail,
          secondPersonNumber: resp.data.secondPersonNumber,
          secondPersonPos: resp.data.secondPersonPos,
          extraWish: resp.data.extraWish,
          send: resp.data.send
        }
        console.log(this.formData)
      }).catch((e) => {
        console.error(e)
      })

      this.loadingState = false;
    },
  },

  mounted() {
    this.companyId = localStorage.getItem("companyId")
    this.$store.state.showNavbarMini = false;
    const sidenav_show = document.querySelector(".g-sidenav-show");
    sidenav_show.classList.remove("g-sidenav-hidden");
    sidenav_show.classList.add("g-sidenav-pinned");
    sidenav_show.children[0].style.setProperty("background-color", "white", "important");

    this.setForm()
  },
};

</script>