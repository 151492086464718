<template>
  <soft-button @click="open" :disabled="addedImages >= 5">
    Bild hinzufügen
  </soft-button>
  <div ref="exmod" class="modal fade" id="addImageModal" tabindex="-1" aria-labelledby="exampleModalLabel">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <div class="d-flex justify-content-end">
            <button
                class="border-0"
                style="background-color:white"
                data-bs-dismiss="modal"
                id="close-modal"
            >
              <font-awesome-icon :icon="['fa', 'xmark']" size="lg" />
            </button>
          </div>
          <div class="m-auto col-12 col-lg-10">
            <form class="multisteps-form__form" @submit.prevent>
              <!--single form panel-->
              <div v-if="activeStep === 0" class="p-3 bg-white card">
                <div class="text-center row">
                  <div class="mx-auto mb-4 mt-2 col-10">
                    <h5 class="font-weight-normal"> Lade hier deine Bilder hoch! </h5>
                  </div>
                  <div class="col-12px-3 text-dark">
                    <form
                        @submit.prevent="imageFileUpload"
                        id="dropzone"
                        class="form-control dropzone"
                    >
                      <div class="fallback">
                        <input class="text-dark" name="file" type="file" multiple @change="handleFileDeleted"/>
                      </div>
                    </form>
                    <div v-if="1 > 0">
                      <div class="text-danger small py-3">{{ message }}</div>
                    </div>
                    <div class="mt-2 button-row d-flex">
                      <button
                          class="mb-0 btn bg-primary ms-auto js-btn-next"
                          @click="imageFileUpload"
                      >
                        Weiter
                      </button>
                    </div>
                  </div>
                </div>

              </div>
              <!--single form panel-->
              <div v-if="activeStep === 1" :class="activeStep === 1 ? activeClass : ''"
                   class="p-3 bg-white card pt-4">
                <!--Imge bearbeiten comp-->
                <div class="button-row d-flex flex-column" v-for="(item, idx) in combinedList">
                  <div v-if="item['base64Image'].includes('image') && cropperStep === idx"
                       class="d-flex justify-content-center">
                    <div class="col-12 col-md-8">
                      <h5 class="font-weight-bolder mb-4">Schneide deine Bilder zu</h5>
                      <div :id="'cropper' + idx" :key="idx" class="mb-5">
                        <div style="display: flex; justify-content: center;" class="q-mb-md">
                          <button style="border: 0px;" :style="item.ratio === '1/1' ? 'rgb(126, 17, 69)' : 'grey'" @click="changeRatio(item)" :disabled="item.ratio === '9/16'">9/16</button>
                          <button
                              :style="'background-color:' + item.ratio === '1/1' ? 'rgb(126, 17, 69)' : 'grey'"
                              style="border: 0px;"
                              @click="changeRatio(item)" :disabled="item.ratio === '1/1'">1/1</button>
                        </div>
                        <cropper ref="cropper"
                                 class="cropper"
                                 :stencil-props="{aspectRatio: item.ratio === '9/16' ? 9/16 : 1/1, initialAspectRatio: 9/16}"
                                 :src="item.base64Image"
                                 @change="change"></cropper>
                      </div>
                    </div>
                  </div>
                  <div v-if="item['base64Image'].includes('video') && cropperStep === idx"
                       :class="activeStep === 1 ? activeClass : ''">
                    <video :src="item.base64Image" muted autoplay controls class="col-12 mb-3"></video>
                  </div>
                </div>
                <div class="d-flex button-row">
                  <button
                      class="mb-0 btn bg-dark js-btn-next text-white"
                      type="button"
                      title="Zurücksetzen"
                      @click="close"
                  >
                    Zurücksetzen
                  </button>
                  <button
                      v-if="cropperStep === combinedList.length-1"
                      class="mb-0 btn bg-primary ms-auto js-btn-next"
                      type="button"
                      title="Fortsetzen"
                      @click="lastCropStep(combinedList[cropperStep])"
                  >
                    Weiter
                  </button>
                  <button
                      v-if="cropperStep < combinedList.length-1"
                      class="mb-0 btn bg-primary ms-auto js-btn-next"
                      type="button"
                      title="Fortsetzen"
                      @click="nextCropperStep(combinedList[cropperStep])"
                  >
                    Nächstes
                  </button>
                </div>
              </div>
              <div v-if="activeStep === 2" :class="activeStep === 2 ? activeClass : ''" class="p-3 card">
                <h5 class="font-weight-bolder">Hochladen</h5>
                <div class="row pb-3 width-100 d-flex justify-content-center align-items-center">
                  <div class="col-auto">
                    <div class="col-auto">
                      <div class="card-body p-0 mt-3" style="max-height: 43rem">
                        <img v-if="checkForImage(resultList[uploadSlider])"
                             class="border-radius-lg"
                             style="max-height: 30rem; object-fit: contain; max-width: 20rem;"
                             :src="resultList[uploadSlider]"
                             alt="product_image"
                        />
                        <div v-if="!checkForImage(resultList[uploadSlider])">
                          <video style="max-height: 30rem; object-fit: contain; max-width: 20rem" :src="resultList[uploadSlider]['base64Image']"
                                 muted autoplay controls class="col-12 mb-3"></video>
                        </div>
                        <div style="display: flex; justify-content: space-between; margin-bottom: 1rem">
                          <soft-button @click="uploadSlider--"
                                       style="margin-top: 10px;" :style="uploadSlider > 0 ? '' : 'display: contents'"
                          >
                            <i class="fa fa-arrow-left" id="left"></i>
                          </soft-button>
                          <soft-button v-if="uploadSlider < resultList.length-1" @click="uploadSlider++"
                                       style="margin-top: 10px;">
                            <i class="fa fa-arrow-right" id="right"></i>
                          </soft-button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex button-row">
                    <button
                        class="mb-0 btn bg-dark js-btn-next text-white"
                        type="button"
                        title="Zurücksetzen"
                        @click="close"
                    >
                      Zurücksetzen
                    </button>
                    <button
                        v-if="stage !== 'uploader'"
                        class="mb-0 btn bg-primary ms-auto js-btn-next"
                        type="button"
                        title="Hochladen"
                        :disabled="!PostUploadedList.length > 0"
                        @click="upload"
                    >
                      Hochladen
                    </button>
                    <button
                        v-if="stage === 'uploader'"
                        class="mb-0 btn bg-primary ms-auto js-btn-next"
                        type="button"
                        title="Hinzufügen"
                        :disabled="!PostUploadedList.length > 0"
                        @click="emit"
                    >
                      Hinzufügen
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

import api, {apiGet, apiDelete, apiPost} from "@/api/api";
import Dropzone from "dropzone";
import {Modal} from "bootstrap";

import SoftButton from "@/components/SoftButton.vue";

import {Cropper} from "vue-advanced-cropper";
import VueCropper from "@/components/VueCropper.vue";

import { BvModal } from 'bootstrap';

export default {
  name: "AddImage",
  components: {VueCropper, Cropper, SoftButton},
  data() {
    return {
      activeClass: "js-active position-relative",
      formSteps: 2,
      imgTooSmall: false,
      message: "",
      fileUploader: null,
      activeStep: 0,
      uploadSlider: 0,
      cropperStep: 0,
      cropImg: "",
      resultList: [],
      base64Video: [],
      combinedList: [],
      PostUploadedList: [],
      uniqueModal: undefined,
    }
  },
  props:{
    id: {
      type: Number,
    },
    images:{
      type: Array,
      default: []
    },
    stage:{
      type: String
    },
    addedImages:{
      type: Array,
      default: []
    }
  },
  methods: {
    uploadData() {
      let media = this.resultList.map((x) => {
        let val = x
        if (typeof x === 'object') {
          val = x['base64Image']
        }
        return {
          base64Image: val
        }
      })
      if (this.addedImages.length > 0) {
        media.push(...this.addedImages)
      }
      return {
        images: media,
      }
    },
    open(){
      this.fileUploader = null
      this.uniqueModal = undefined

      if (Dropzone.instances.length > 0) {
        Dropzone.instances.forEach((e) => {
          e.off();
          e.removeAllFiles(true);
          e.destroy();
          e = null;
        });
      }

      this.uniqueModal = new Modal(document.getElementById("addImageModal"),{ keyboard: false });
      this.uniqueModal.show();

      this.reset()

      setTimeout(() => {
        this.createDropzone();
      }, 300);
    },
    async upload() {
      // ?companyId=' + this.$store.state.activeId, this.uploadData
      await api.post('/post/addImage',
          this.uploadData(),
         {params: {postId: this.id}},
      ).then(() => {
        this.$router.push({name: 'Posts'})
      })
      this.$router.go(0)
    },
    emit(){
      // ?companyId=' + this.$store.state.activeId, this.uploadData
      this.$emit("update:list", this.uploadData())
      this.reset()
      document.getElementById('close-modal').click()
    },
    reset(){
      this.resultList = []
      this.activeStep = 0
      this.uploadSlider = 0
      this.cropperStep = 0
      this.cropImg = ""
      this.base64Video = []
      this.combinedList = []
      this.PostUploadedList = []
    },
    checkForImage(item){
      let val = item
      if (typeof item === 'object')
      {
        val = item['base64Image']
      }
      return val.includes('image')
    },
    nextCropperStep(item) {
      if (this.checkForImage(item)) {
        this.cropImage()
      }
      this.cropperStep++;
    },
    change({coordinates, canvas}) {
      this.coordinates = coordinates;
      this.canvas = canvas;
    },
    cropImage() {
      const result = this.$refs.cropper.getResult();
      this.resultList.push(result.canvas.toDataURL("image/jpg"))
    },
    lastCropStep(item){
      if (this.checkForImage(item)){
        this.cropImage()
      }
      this.nextStep()
    },
    async nextStep() {
      if (!this.imgTooSmall) {
        if (this.activeStep < this.formSteps) {
          this.activeStep += 1;

        } else {
          this.activeStep -= 1;
        }
      } else {
        this.PostUploadedList = []
        this.message = "Das Bild hat zu wenig Pixel"
        setTimeout(() => {
          this.message = ""
        }, 3000);
      }
    },
    changeRatio(item) {
      if (item.ratio === "1/1")
      {
        item.ratio = "9/16"
      }
      else {
        item.ratio = "1/1"
      }
      this.$refs.cropper.close();
    },
    imageFileUpload() {
      if(this.fileUploader === undefined){
        console.log("failed no files!")
        return
      }

      const fileNames = this.fileUploader.files.map(file => file.name);

      const uniqueNames = new Set();
      const duplicateNames = new Set();

      for (const name of fileNames) {
        if (uniqueNames.has(name)) {
          duplicateNames.add(name); // Falls bereits vorhanden, als Duplikat markieren
        } else {
          uniqueNames.add(name);
        }
      }
      const hasDuplicates = duplicateNames.size > 0;

      if (hasDuplicates)
      {
        this.message = "Es gibt mindestens 2 Dateien mit dem selben Namen"
        setTimeout(() => {
          this.message = ""
        }, 3000);
      }
      else {

        this.PostUploadedList = []

        this.PostUploadedList = this.fileUploader.files.map((x) => {
              this.imgTooSmall = (x.height < 1080 || x.width < 1080)
              return {
                base64Image: x.dataURL,
                ratio: "9/16"
              }
            }
        )

        this.PostUploadedList = this.PostUploadedList.filter((item) => item.base64Image !== undefined);
        this.combinedList = this.PostUploadedList


        let videoList = []
        for (let item of this.base64Video)
        {
          for (let file of this.fileUploader.files)
          {
            if (file.name === item.name)
            {
              videoList.push(item)
            }
          }
        }

        for (var i = 0; i < videoList.length; i++)
        {
          this.resultList.push({base64Image: videoList[i].base64String})
          this.combinedList.push({base64Image: videoList[i].base64String})
        }

        if (this.combinedList.length + this.addedImages.length <= 5 && this.combinedList.length !== 0) {
          this.message = ""
          if (!this.noCategories) {
            this.nextStep()
          }
        } else if (this.combinedList.length === 0) {
          this.message = "Du musst mindestens eine Datei hochladen!"
          setTimeout(() => {
            this.message = ""
          }, 3000);
        } else {
          this.message = "Du kannst nicht mehr als 5 Bilder pro Post hochladen!"
          setTimeout(() => {
            this.message = ""
          }, 3000);
        }
      }
    },
    createDropzone: function () {

      let fileList = document.querySelectorAll(".dz-file-preview")
      for(let file of fileList) {
        file.style.display = "none"
      }

      const drop = document.getElementById("dropzone")

      if (!drop) {
        console.error("Dropzone-Element nicht gefunden!");
        return;
      }

      const self = this;
      this.base64Video = []; // Initialize as an array to store base64 strings.

      this.fileUploader = new Dropzone(drop, {
        url: '/',
        maxFilesize: 5, // 10MB
        acceptedFiles: ".jpg, .png, .gif, .mp4",
        autoProcessQueue: false,
        reload: false,
        addRemoveLinks: true,
        dictRemoveFile: 'Entfernen',
        dictDefaultMessage: 'Datei hier hochladen',
        init: function () {
          this.on("addedfile", function (file) {
            var reader = new FileReader();
            if (file.type === 'video/mp4') {
              let videoElement = document.createElement('video');
              videoElement.src = URL.createObjectURL(file);

              // Convert video to base64 and push to array
              const videoPromise = new Promise((resolve, reject) => {
                videoElement.addEventListener('loadedmetadata', async () => {
                  try {
                    const response = await fetch(videoElement.src);
                    const blob = await response.blob();

                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result);
                    reader.onerror = reject;
                    reader.readAsDataURL(blob);
                  } catch (error) {
                    reject(error);
                  }
                });
                // Load the video metadata if not already done
                videoElement.load();
              });
              videoPromise.then(base64String => {
                self.base64Video.push({
                  base64String: base64String,
                  name: file.name,
                }); // Push base64 string to the array
              }).catch(error => {
                console.error("Error processing video:", error);
              });
            }
            reader.readAsDataURL(file);
          },
          this.on("removedfile", function (file){
            if (self.base64Video)
            {
              for (var i = 0; i < self.base64Video.length; i++){
                if (self.base64Video[i].name === file.name)
                {
                  self.base64Video = self.base64Video.filter(function(item) {
                    return item.name !== file.name
                  })
                }
              }
            }
          })
          );
        }
      });
    },
  },
  async mounted() {
    const modalElement = this.$refs.exmod;

    if (modalElement) {
      modalElement.addEventListener("hidden.bs.modal", this.close);
    }
  },
  beforeUnmount() {
    const modalElement = this.$refs.exmod;
    if (modalElement) {
      modalElement.removeEventListener("hidden.bs.modal", this.close);
    }
  },
};

</script>

<style scoped>
</style>